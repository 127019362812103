import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row } from "reactstrap";

import * as actions from '../../store/SupplierInvoice/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from "../../helpers/browseFormControls";
import * as columnsSupplierInvoiceCandidate from '../../definitions/columns/supplierInvoiceCandidate';
import * as config from '../../config';
import * as dateAndTimeUtils from "../../helpers/dateAndTimeUtils";
import * as editFormControls from '../../helpers/editFormControls';
import EditSupplierAndBankAccounts from "components/Pages/EditSupplierAndBankAccounts";
import * as formatUtils from '../../helpers/formatUtils';
// import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import classes from './Pages.module.css';


class SupplierInvoiceCandidateBrowse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCandidates: [],
            actionsCandidateDropdownOpen: [],
            actionsWithSelectedDropdownOpen: false,
            showEditSupplierForm: false,
            editFieldSubform: [],
            subformValue: "",
            subformRowIdx: null,
            subformField: null,
            newOrUpdatedSupplier: null,
            supplierInvoiceCandidates: [],
            supplierBankAccountsToUpdate: []
        }
    }

    componentDidMount() {
        this.props.onGetSupplierInvoiceCandidates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loadingSupplier && !this.props.loadingSupplier && this.props.supplier) {
            this.setState({
                showEditSupplierForm: true,
                newOrUpdatedSupplier: {
                    ...this.props.supplier,
                    bankAccounts: this.state.supplierBankAccountsToUpdate
                }
            });
        }

        if (prevProps.supplierInvoiceCandidates !== this.props.supplierInvoiceCandidates) {
            this.setState({
                supplierInvoiceCandidates: this.props.supplierInvoiceCandidates
            })
        }
    }

    commandButtons = (cell, row, rowIndex, formatExtraData) => {
        // Could not disable or render the buttons differently on the basis of formatExtraData.movingLines, as apparently it does not get updated when needed
        return (
            <React.Fragment>
                <i
                    className="bx bxs-download"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        this.props.onDownloadAttachment(row.id);
                    }}
                />
            </React.Fragment>
        );
    };

    getAndFormatFieldStatus = (row, value) => {
        switch (value) {
            case "customerName":
                return formatUtils.formatMatchingStatus(row.customerNameMatchingStatus);
            case "customerRegNo":
                return formatUtils.formatMatchingStatus(row.customerRegNoMatchingStatus, true);
            case "customerVATRegNo":
                return formatUtils.formatMatchingStatus(row.customerVATRegNoMatchingStatus, true);
            case "supplierName":
                return formatUtils.formatMatchingStatus(row.supplierNameMatchingStatus);
            case "supplierRegNo":
                return formatUtils.formatMatchingStatus(row.supplierRegNoMatchingStatus, true);
            case "supplierVATRegNo":
                return formatUtils.formatMatchingStatus(row.supplierVATRegNoMatchingStatus, true);
            case "invoiceNo":
                return formatUtils.formatInvoiceDuplicationStatus(row.invoiceDuplicationStatus);
            case "dueDate":
                return formatUtils.formatMatchingStatus(row.dueDate ? "OK" : "VALUE_NOT_SPECIFIED");
            case "invoiceCurrencyCode":
                return formatUtils.formatMatchingStatus(row.detectedCurrencyId ? "OK" : "MATCH_NOT_FOUND");
            case "totalInvoiceAmountWithoutVAT":
                return formatUtils.formatMatchingStatus(row.totalInvoiceAmountWithoutVATMatchingStatus);
            case "totalVATAmount":
                return formatUtils.formatMatchingStatus(row.totalVATAmountMatchingStatus);
            case "totalInvoiceAmountWithVAT":
                return formatUtils.formatMatchingStatus(row.totalInvoiceAmountWithVATMatchingStatus);
            case "totalPaidAmount":
                return formatUtils.formatMatchingStatus(row.totalPaidAmountMatchingStatus);
            case "totalDebtAmount":
                return formatUtils.formatMatchingStatus(row.totalPaidAmountMatchingStatus);
            case "totalPayableAmount":
                return formatUtils.formatMatchingStatus(row.totalPayableAmountMatchingStatus);
            default:
                return "";
        }
    }

    toggleShowEditFieldSubform = (row, fieldName) => {
        const rowId = row.id;
        this.setState(prevState => {
            const newState = [...prevState.editFieldSubform];

            if (!newState[rowId]) {
                newState[rowId] = {};
            }

            if (newState[rowId][fieldName]) {
                newState[rowId][fieldName] = false;
                return {
                    editFieldSubform: newState
                };
            } else {
                newState[rowId][fieldName] = true;
                const value = columnsSupplierInvoiceCandidate[fieldName].editInputType === "date"
                    ? dateAndTimeUtils.localDateToInputControlValue(row[fieldName])
                    : row[fieldName];
                let idx = null;
                for (let i in prevState.supplierInvoiceCandidates) {
                    if (prevState.supplierInvoiceCandidates[i].id === row.id) {
                        idx = i;
                        break;
                    }
                }
                return {
                    editFieldSubform: newState,
                    subformValue: value,
                    subformField: fieldName,
                    subformRowIdx: idx
                };
            }
        });
    };

    onChangeSubform = (e) => {
        this.setState({
            subformValue: e.target.value
        })
    }

    onApplySubform = (row, value) => {
        if (this.state.subformRowIdx != null && this.state.subformField != null) {
            const editedValue = columnsSupplierInvoiceCandidate[value].editInputType === "date"
                ? dateAndTimeUtils.inputControlValueToLocalDate(this.state.subformValue)
                : this.state.subformValue;
            const updatedCandidate = {
                ...this.state.supplierInvoiceCandidates[this.state.subformRowIdx],
                [this.state.subformField]: editedValue
            };
            this.props.onUpdateCandidate({
                ...updatedCandidate,
                invoiceDate: dateAndTimeUtils.localDateToInputControlValue(updatedCandidate.invoiceDate),
                dueDate: dateAndTimeUtils.localDateToInputControlValue(updatedCandidate.dueDate)
            });
            this.toggleShowEditFieldSubform(row, value);
        }
    }

    onDiscardSubform = (row, value) => {
        if (window.confirm("Discard changes?")) {
            this.toggleShowEditFieldSubform(row, value);
        }
    }

    editFieldSubform = (row, value) => {
        const inputType = columnsSupplierInvoiceCandidate[value].editInputType || "text";
        return (
            <React.Fragment>
                <div style={{
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    zIndex: "10000"
                }}
                    onClick={() => this.onDiscardSubform(row, value)}
                />
                <InputGroup style={{
                    zIndex: "10001"
                }}>
                    <Input
                        type={inputType}
                        value={this.state.subformValue}
                        onChange={this.onChangeSubform}
                        step={inputType === "number" ? 0.01 : null}
                    />
                    <Button
                        color="primary"
                        onClick={() => this.onApplySubform(row, value)}
                    >
                        Apply
                    </Button>
                </InputGroup>
            </React.Fragment>
        );
    }

    editIconIfApplicable = (row, value) => {

        const editIcon = <div
            style={{ height: "100%", display: "flex", alignItems: "flex-end", cursor: "pointer" }}
            onClick={() => this.toggleShowEditFieldSubform(row, value)}
        >
            <i
                className={"bx bx-edit"}
                style={{ fontSize: "18px", marginBottom: "0.3rem" }}
            />
        </div>

        if (columnsSupplierInvoiceCandidate[value].editInputType) {
            return editIcon;
        } else {
            return null;
        }
    }

    createSupplier = (row) => {
        this.setState({
            showEditSupplierForm: true,
            newOrUpdatedSupplier: {
                name: row.supplierName,
                regNo: row.supplierRegNo,
                vatRegNo: row.supplierVATRegNo,
                bankAccounts: row.supplierBankAccounts.map(bankAccount => ({
                    accountNo: bankAccount.accountNo,
                    bankSwiftCode: bankAccount.swiftBIC,
                    bankName: bankAccount.bankName
                }))
            }
        });
    }

    updateSupplierBankAccounts = (row) => {
        this.setState({
            supplierBankAccountsToUpdate: row.supplierBankAccounts.map(bankAccount => ({
                accountNo: bankAccount.accountNo,
                bankSwiftCode: bankAccount.swiftBIC,
                bankName: bankAccount.bankName
            }))
        })
        this.props.onGetSupplierById(row.detectedSupplierId);
        // The rest is done in componentDidUpdate()
    }

    matchCandidates = () => {
        this.props.onMatchCandidates();
    }

    convertCandidates = () => {
        if (window.confirm('All selected rows in the "Ready" status will be converted to supplier invoices')) {
            this.props.onConvertCandidates(this.state.selectedCandidates, this.props.history);
        }
    }

    deleteCandidates = () => {
        if (window.confirm('All selected rows will be deleted')) {
            this.props.onDeleteCandidates(this.state.selectedCandidates);
        }
    }

    toggleCandidateActionsDropdownOpen = (id) => {
        const open = this.state.actionsCandidateDropdownOpen.includes(id);
        let updatedArray;
        if (open) {
            updatedArray = this.state.actionsCandidateDropdownOpen.filter(thisItem => thisItem != id);
        } else {
            updatedArray = [
                ...this.state.actionsCandidateDropdownOpen,
                id
            ];
        }
        this.setState({
            actionsCandidateDropdownOpen: updatedArray
        })
    }

    render() {

        const pageTitle = "Received supplier invoices | " + config.AppName;
        const breadcrumbsTitle = "Supplier invoices";
        const breadcrumbsItem = "Browse received invoices";

        const EditSupplierFormAsVar =
            <EditSupplierAndBankAccounts
                onClose={() => this.setState({ showEditSupplierForm: false })}
                supplier={this.state.newOrUpdatedSupplier}
                returnInvoiceCandidates
            />

        const columns = [
            columnsSupplierInvoiceCandidate.status,
            columnsSupplierInvoiceCandidate.customerName,
            columnsSupplierInvoiceCandidate.supplierName,
            columnsSupplierInvoiceCandidate.invoiceNo,
            columnsSupplierInvoiceCandidate.invoiceDate,
            columnsSupplierInvoiceCandidate.dueDate,
            columnsSupplierInvoiceCandidate.detectedCurrencyCode,
            columnsSupplierInvoiceCandidate.totalInvoiceAmountWithVAT,
            {
                dataField: "commandButtons",
                text: "",
                formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
            }
        ];

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedCandidates, row, isSelect);
                this.setState({
                    selectedCandidates: selectedRows
                });
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedCandidates, rows, isSelect)
                this.setState({
                    selectedCandidates: selectedRows
                });
            }
        };

        const expandRow = {
            renderer: (row) => {

                const columnsToShow = [
                    "customerName",
                    "customerRegNo",
                    "customerVATRegNo",
                    "supplierName",
                    "supplierRegNo",
                    "supplierVATRegNo",
                    "invoiceNo",
                    "invoiceDate",
                    "dueDate",
                    "invoiceCurrencyCode",
                    "totalInvoiceAmountWithoutVAT",
                    "totalVATAmount",
                    "totalInvoiceAmountWithVAT"
                ];

                if (row.totalDebtAmount != 0) {
                    columnsToShow.push("totalDebtAmount");
                    columnsToShow.push("totalPayableAmount");
                } else if (row.alreadyPaidAmount != 0) {
                    columnsToShow.push("alreadyPaidAmount");
                    columnsToShow.push("totalPayableAmount");
                } else if (row.totalInvoiceAmountWithVAT != row.totalPayableAmount) {
                    columnsToShow.push("totalPayableAmount");
                }

                const rows_CandidateBasicData = columnsToShow.map((value, idx) => {
                    const thisColumn = columnsSupplierInvoiceCandidate[value];
                    const thisValue = row[value];
                    const thisColumnValueFormatted =
                        (value === "invoiceDate"
                            || value === "dueDate"
                            || value === "totalInvoiceAmountWithoutVAT"
                            || value === "totalVATAmount"
                            || value === "totalInvoiceAmountWithVAT"
                            || value === "alreadyPaidAmount"
                            || value === "totalDebtAmount"
                            || value === "totalPayableAmount"
                        )
                            ? thisColumn.formatter(thisValue)
                            : thisValue;

                    const rowShowingValue = <div style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                        <div style={{ marginBottom: "0.2rem" }}>
                            {thisColumnValueFormatted}
                        </div>
                        &nbsp;
                        {this.getAndFormatFieldStatus(row, value)}
                        &nbsp;
                        {this.editIconIfApplicable(row, value)}
                    </div>

                    return (<React.Fragment>
                        <tr key={idx}>
                            <th>{thisColumn.text}</th>
                            <td>
                                {this.state.editFieldSubform && this.state.editFieldSubform[row.id] && this.state.editFieldSubform[row.id][value]
                                    ? this.editFieldSubform(row, value)
                                    : rowShowingValue}
                            </td>
                        </tr>
                    </React.Fragment>)
                });

                const rows_CandidateBankAccounts = row.supplierBankAccounts.map((bankAccount, idx) => {
                    return (<React.Fragment>
                        <tr key={idx}>
                            <td>{bankAccount.accountNo} {formatUtils.formatMatchingStatus(bankAccount.matchingStatus)}</td>
                            <td>{bankAccount.swiftBIC}</td>
                            <td>{bankAccount.bankName}</td>
                        </tr>
                    </React.Fragment>)
                });

                const undetectedBankAccounts = row.supplierBankAccounts.filter(bankAccount => bankAccount.matchingStatus !== "OK");
                const hasBankAccountsToUpdate = row.detectedSupplierId && undetectedBankAccounts && undetectedBankAccounts.length;

                return (
                    <table className="table">
                        <tbody style={{ borderColor: "#FFFFFF" }}>
                            <tr>
                                <td style={{ border: "0px transparent" }}>
                                    <table className={classes.SupplierInvoiceCandidateExpandedTable}>
                                        {rows_CandidateBasicData}
                                    </table>
                                </td>
                                <td style={{ border: "0px transparent", height: "100%" }}>
                                    <table style={{ border: "0px transparent", height: "100%" }}>
                                        <tr>
                                            <td>
                                                <div style={{ height: "100%", display: "flex", alignItems: "flex-start" }}>
                                                    <table className={classes.SupplierInvoiceCandidateExpandedBankAccounts}>
                                                        <tr>
                                                            <th>Account No.</th>
                                                            <th>SWIFT-BIC</th>
                                                            <th>Bank name</th>
                                                        </tr>
                                                        {rows_CandidateBankAccounts}
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: "100%" }}>
                                                <div style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                                                    <div style={{ marginLeft: "auto", marginRight: "0" }}>
                                                        <Dropdown
                                                            isOpen={this.state.actionsCandidateDropdownOpen.includes(row.id)}
                                                            toggle={() => this.toggleCandidateActionsDropdownOpen(row.id)}
                                                        >
                                                            <DropdownToggle
                                                                color="primary"
                                                                disabled={this.props.matching || this.props.loadingSupplier}
                                                                caret
                                                            >
                                                                Actions...
                                                                &nbsp;
                                                                {this.props.matching || this.props.loadingSupplier ? editFormControls.buttonSpinner() : null}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {row.detectedSupplierId ? null : <DropdownItem onClick={() => this.createSupplier(row)}>Create supplier and bank accounts</DropdownItem>}
                                                                {hasBankAccountsToUpdate ? <DropdownItem onClick={() => this.updateSupplierBankAccounts(row)}>Update supplier bank accounts</DropdownItem> : null}
                                                                <DropdownItem onClick={this.matchCandidates}>Match again</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
            showExpandColumn: true,
            //expandByColumnOnly: true,
            expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
        }

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.state.supplierInvoiceCandidates}
                    columns={columns}
                    pagination={paginationFactory()}
                    // rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                    selectRow={selectRow}
                    expandRow={expandRow}
                />
            </div>

        const actionsDropdown =
            <Dropdown
                isOpen={this.state.actionsWithSelectedDropdownOpen}
                toggle={() => this.setState({
                    actionsWithSelectedDropdownOpen: !this.state.actionsWithSelectedDropdownOpen
                })}
            >
                <DropdownToggle
                    color="primary"
                    disabled={this.props.converting || !this.state.selectedCandidates || !this.state.selectedCandidates.length}
                    caret
                >
                    With selected...
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={this.convertCandidates}>Convert to invoices where possible</DropdownItem>
                    {/* <DropdownItem onClick={this.updateData1}>Update bank accounts if status is "Data to update"</DropdownItem>
                    <DropdownItem onClick={this.updateData2}>Update data from partial duplicates</DropdownItem>
                    <DropdownItem onClick={this.updateData3}>Delete duplicates</DropdownItem>*/}
                </DropdownMenu>
            </Dropdown>

        return (

            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>

                    {this.state.showEditSupplierForm ? EditSupplierFormAsVar : null}

                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {editFormControls.errorAlert(this.props.error)}

                                        {editFormControls.formLoadingSpinner(this.props.loadingCandidates)}

                                        {!this.props.loadingCandidates && this.state.supplierInvoiceCandidates ?
                                            table
                                            : null}

                                        <div style={{ display: 'flex' }}>
                                            {actionsDropdown}

                                            &nbsp;

                                            {editFormControls.deleteButton(this.props.deleting, this.deleteCandidates, true, (!this.state.selectedCandidates || !this.state.selectedCandidates.length))}
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ supplier, supplierInvoice }) => ({
    converting: supplierInvoice.converting,
    error: supplierInvoice.error,
    loadingCandidates: supplierInvoice.loading,
    loadingSupplier: supplier.loading,
    matching: supplierInvoice.matching,
    supplierInvoiceCandidates: supplierInvoice.supplierInvoiceCandidates,
    supplier: (supplier.suppliers && supplier.suppliers.length == 1 ? supplier.suppliers[0] : null)
})

const mapDispatchToProps = dispatch => ({
    onConvertCandidates: (idList, history) => dispatch(actions.supplierInvoiceCandidateConvert(idList, history)),
    onDeleteCandidates: (idList) => dispatch(actions.supplierInvoiceCandidateDelete(idList)),
    onDownloadAttachment: (id) => dispatch(actions.supplierInvoiceCandidateDownloadAttachment(id)),
    onGetSupplierById: (id) => dispatch(actionsSupplier.supplierGetById(id)),
    onGetSupplierInvoiceCandidates: () => dispatch(actions.supplierInvoiceCandidateGetAll()),
    onMatchCandidates: () => dispatch(actions.supplierInvoiceCandidateMatch()),
    onUpdateCandidate: (candidate) => dispatch(actions.supplierInvoiceCandidateUpdate(candidate))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SupplierInvoiceCandidateBrowse));

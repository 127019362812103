	// Tenant, Company

	export const TENANT_CREATE = "/tenant";
	export const TENANT_GET_BY_ID = TENANT_CREATE + "/{tenantId}";
	export const TENANT_GET_ALL = "/tenant-get-all";

	export const COMPANY_CREATE_OR_UPDATE = TENANT_GET_BY_ID + "/company";
	export const COMPANY_GET_BY_ID = TENANT_GET_BY_ID + "/company/{companyId}";
	export const COMPANY_GET_ALL = TENANT_GET_BY_ID + "/company/all";
	export const COMPANY_GET_ALL_FOR_TENANT = TENANT_GET_BY_ID + "/company/all-for-tenant";
	export const COMPANY_GET_LAST = TENANT_GET_BY_ID + "/company/last";

	export const REGEX_ANY_TENANT_AND_COMPANY_ID = "^" + TENANT_CREATE + "/.*?/company/.*?";
	export const REGEX_DETECT_TENANT_ID = "^" + TENANT_CREATE + "/(.*?)/.*";
	export const REGEX_DETECT_TENANT_ID_REPLACEMENT = "$1";
	export const REGEX_DETECT_COMPANY_ID = "^" + TENANT_CREATE + "/.*?/company/(.*?)/.*";
	export const REGEX_DETECT_COMPANY_ID_REPLACEMENT = "$1";

	// end Tenant, Company

	// Everything else, in alphabetical order

	export const ALLOCATION_CONFIRM = COMPANY_GET_BY_ID + "/allocation/apply";
	export const ALLOCATION_CREATE = COMPANY_GET_BY_ID + "/allocation";
	export const ALLOCATION_CREATE_AUTO = COMPANY_GET_BY_ID + "/allocation/auto/{bankStatementId}";
	export const ALLOCATION_GET_CONFIRMED = COMPANY_GET_BY_ID + "/allocation/applied";
	export const ALLOCATION_GET_EXPORTED = COMPANY_GET_BY_ID + "/allocation/exported";
	export const ALLOCATION_GET_BY_STATEMENT = COMPANY_GET_BY_ID + "/allocation/{bankStatementId}";
	export const ALLOCATION_GET_BY_CUSTOMER_INVOICE = COMPANY_GET_BY_ID + "/customer-invoice/allocations/{customerInvoiceId}";
	export const ALLOCATION_GET_BY_PAYMENT = COMPANY_GET_BY_ID + "/payment/allocations/{bankStatementId}";
	export const ALLOCATION_GET_BY_SUPPLIER_INVOICE = COMPANY_GET_BY_ID + "/supplier-invoice/allocations/{bankStatementId}";
	export const ALLOCATION_REVERT_ALL = COMPANY_GET_BY_ID + "/allocation/revert-all/{bankStatementId}";
	export const ALLOCATION_REVERT_BY_ID = COMPANY_GET_BY_ID + "/allocation/revert/{bankStatementRecordId}";
	export const ALLOCATION_SET_LIST_EXPORTED = COMPANY_GET_BY_ID + "/allocation/set-exported";

	export const ALLOCATION_CRITERION_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/allocation-criterion";
	export const ALLOCATION_CRITERION_GET_ALL = COMPANY_GET_BY_ID + "/allocation-criterion/all";
	export const ALLOCATION_CRITERION_GET_ALL_BY_TYPE = COMPANY_GET_BY_ID
			+ "/allocation-criterion/type/{allocationCriterionType}";
	export const ALLOCATION_CRITERION_GET_BY_ID = COMPANY_GET_BY_ID + "/allocation-criterion/{allocationCriterionId}";
	export const ALLOCATION_CRITERION_MOVE_UP = COMPANY_GET_BY_ID + "/allocation-criterion/up/{allocationCriterionId}";
	export const ALLOCATION_CRITERION_MOVE_DOWN = COMPANY_GET_BY_ID + "/allocation-criterion/down/{allocationCriterionId}";

	export const AVAILABLE_MODULES_GET_ALL = TENANT_GET_BY_ID + "/available-modules";

	export const BANK_ACCOUNT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/bank-account";
	export const BANK_ACCOUNT_DC_CHECK_CONNECTION = COMPANY_GET_BY_ID + "/bank-account/{bankAccountId}/check-connection";
	export const BANK_ACCOUNT_GET_BY_ID = COMPANY_GET_BY_ID + "/bank-account/{bankAccountId}";
	export const BANK_ACCOUNT_GET_ALL = COMPANY_GET_BY_ID + "/bank-account/all";

	export const BANK_STATEMENT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/bank-statement";
	export const BANK_STATEMENT_GET_ALL = COMPANY_GET_BY_ID + "/bank-statement/all";
	export const BANK_STATEMENT_GET_BY_ID = COMPANY_GET_BY_ID + "/bank-statement/{bankStatementId}";
	export const BANK_STATEMENT_LOAD_FROM_FILE = COMPANY_GET_BY_ID + "/bank-statement/load-from-file";
	export const BANK_STATEMENT_RECEIVE = COMPANY_GET_BY_ID + "/bank-statement/receive/{bankAccountId}";
	export const BANK_STATEMENT_RECEIVE_ALL = COMPANY_GET_BY_ID + "/bank-statement/receive/all";
	export const BANK_STATEMENT_REFRESH = COMPANY_GET_BY_ID + "/bank-statement/refresh";
	export const BANK_STATEMENT_REFRESH_AS_REGEX = REGEX_ANY_TENANT_AND_COMPANY_ID + "/bank-statement/refresh";
	export const BANK_STATEMENT_REQUEST = COMPANY_GET_BY_ID + "/bank-statement/request";

	export const BANK_STATEMENT_RECORD_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/bank-statement-record";
	export const BANK_STATEMENT_RECORD_GET_BY_ID = COMPANY_GET_BY_ID + "/bank-statement-record/{bankStatementRecordId}";
	export const BANK_STATEMENT_RECORD_GET_ALL = COMPANY_GET_BY_ID + "/bank-statement-record/all";

	export const CM20PAYMENT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/cm20-payment";
	export const CM20PAYMENT_GET_BY_ID = COMPANY_GET_BY_ID + "/cm20-payment/{cm20PaymentId}";
//	export const CM20PAYMENT_GET_FILE_BY_ID = COMPANY_GET_BY_ID + "/cm20-payment/{cm20PaymentId}/file";
//	export const CM20PAYMENT_GET_FILE_LIST_BY_ID = COMPANY_GET_BY_ID + "/cm20-payment/file";
	export const CM20PAYMENT_GET_ALL = COMPANY_GET_BY_ID + "/cm20-payment/all";
	export const CM20PAYMENT_GET_ERRONEOUS = COMPANY_GET_BY_ID + "/cm20-payment/erroneous";
	export const CM20PAYMENT_GET_SIGNED = COMPANY_GET_BY_ID + "/cm20-payment/signed";
	export const CM20PAYMENT_GET_UNSIGNED = COMPANY_GET_BY_ID + "/cm20-payment/unsigned";
	export const CM20PAYMENT_SIGN = COMPANY_GET_BY_ID + "/cm20-payment-sign";
	export const CM20PAYMENT_UPLOAD_SIGNED_FILES = COMPANY_GET_BY_ID + "/cm20-payment-upload-signed-files";

	export const CM20PAYMENT_FILE_CREATE_ALL_POSSIBLE_PAYMENTS = COMPANY_GET_BY_ID + "/cm20-payment-create-possible";
	export const CM20PAYMENT_FILE_GET_FILTERED = COMPANY_GET_BY_ID + "/cm20-payment-file/filtered/{filter}";
	export const CM20PAYMENT_FILE_SIGN_INIT = COMPANY_GET_BY_ID + "/cm20-payment-file-sign-init";
	export const CM20PAYMENT_FILE_SIGN_FINALIZE = COMPANY_GET_BY_ID + "/cm20-payment-file-sign-finalize";
	export const CM20PAYMENT_FILE_SIGN_RESET = COMPANY_GET_BY_ID + "/cm20-payment-file-sign-reset";
	export const CM20PAYMENT_FILE_SIGN_VIA_SMART_ID_INIT = COMPANY_GET_BY_ID + "/cm20-payment-file-sign-smart-id-init";
	export const CM20PAYMENT_FILE_SIGN_VIA_SMART_ID_FINALIZE = COMPANY_GET_BY_ID
			+ "/cm20-payment-file-sign-smart-id-finalize";

	export const CM20PAYMENT_LINE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/cm20-payment-line";
	export const CM20PAYMENT_LINE_GET_BY_ID = COMPANY_GET_BY_ID + "/cm20-payment-line/{cm20PaymentLineId}";
	export const CM20PAYMENT_LINE_GET_ALL = COMPANY_GET_BY_ID + "/cm20-payment-line/all";
	export const CM20PAYMENT_LINE_GET_ORPHANS = COMPANY_GET_BY_ID + "/cm20-payment-line/orphans";

	export const COMPANY_SETTINGS_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/company-settings";
	export const COMPANY_SETTINGS_GET = COMPANY_GET_BY_ID + "/company-settings";
	export const COMPANY_SETTINGS_UPDATE_SUPPLIER_INVOICE_PROCESSING = COMPANY_GET_BY_ID
			+ "/company-settings/supplier-invoice-processing";

	export const COUNTER_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/counter";
	export const COUNTER_GET_AND_UPDATE = COMPANY_GET_BY_ID + "/counter/get-and-update/{counterType}";
	export const COUNTER_GET_BY_ID = COMPANY_GET_BY_ID + "/counter/{counterId}";
	export const COUNTER_GET_ALL = COMPANY_GET_BY_ID + "/counter/all";

	export const COUNTRY_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/country";
	export const COUNTRY_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/country/bulk";
	export const COUNTRY_GET_BY_ID = COMPANY_GET_BY_ID + "/country/{countryId}";
	export const COUNTRY_GET_ALL = COMPANY_GET_BY_ID + "/country/all";
	export const COUNTRY_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/country/external-id-mappings";

	export const CRM_BANK_IMPLEMENTATION_GET_ALL = "/crm-bank-implementation";

	export const CRM_EXTERNAL_DATA_CONNECTOR_DOWNLOAD_LIBRARY = "/crm-external-data-connector-download-library";
	export const CRM_EXTERNAL_DATA_CONNECTOR_VERSION = "/crm-external-data-connector-version";

	export const CRM_MAINTENANCE_NOTICE_CREATE_OR_UPDATE = "/crm-maintenance";
	export const CRM_MAINTENANCE_NOTICE_GET_BY_ID = "/crm-maintenance/{crmMaintenanceNoticeId}";
	export const CRM_MAINTENANCE_NOTICE_GET_PENDING = "/crm-maintenance/pending";

	export const CURRENCY_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/currency";
	export const CURRENCY_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/currency/bulk";
	export const CURRENCY_GET_BY_ID = COMPANY_GET_BY_ID + "/currency/{currencyId}";
	export const CURRENCY_GET_ALL = COMPANY_GET_BY_ID + "/currency/all";
	export const CURRENCY_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/currency/external-id-mappings";

	export const CURRENCY_HISTORY_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/currency-history";
	export const CURRENCY_HISTORY_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/currencyHistory/bulk";
	export const CURRENCY_HISTORY_DELETE_BY_CURRENCY_ID = COMPANY_GET_BY_ID + "/currency-history/currency/{currencyId}";
	export const CURRENCY_HISTORY_DELETE_BY_CURRENCY_ID_LIST = COMPANY_GET_BY_ID + "/currency-history/currency-id-list";
	export const CURRENCY_HISTORY_GET_BY_ID = COMPANY_GET_BY_ID + "/currency-history/{currencyHistoryId}";
	export const CURRENCY_HISTORY_GET_ALL = COMPANY_GET_BY_ID + "/currency-history/all";
	export const CURRENCY_HISTORY_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/currency-history/external-id-mappings";

	export const CUSTOMER_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer";
	export const CUSTOMER_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/customer/bulk";
	export const CUSTOMER_GET_BY_ID = COMPANY_GET_BY_ID + "/customer/{customerId}";
	export const CUSTOMER_GET_ALL = COMPANY_GET_BY_ID + "/customer/all";
	export const CUSTOMER_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/customer/external-id-mappings";

	export const CUSTOMER_AGREEMENT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer-agreement";
	export const CUSTOMER_AGREEMENT_GET_BY_ID = COMPANY_GET_BY_ID + "/customer-agreement/{customerAgreementId}";
	export const CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID = COMPANY_GET_BY_ID + "/customer-agreement/customer/{customerId}";
	export const CUSTOMER_AGREEMENT_GET_ALL = COMPANY_GET_BY_ID + "/customer-agreement/all";

	export const CUSTOMER_INVOICE_COPY = COMPANY_GET_BY_ID + "/customer-invoice/copy/{customerInvoiceId}";
	export const CUSTOMER_INVOICE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer-invoice";
	export const CUSTOMER_INVOICE_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/customer-invoice/bulk";
	export const CUSTOMER_INVOICE_GET_BY_ID = COMPANY_GET_BY_ID + "/customer-invoice/{customerInvoiceId}";
	export const CUSTOMER_INVOICE_GET_ALL = COMPANY_GET_BY_ID + "/customer-invoice/all";
	export const CUSTOMER_INVOICE_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/customer-invoice/external-id-mappings";
	export const CUSTOMER_INVOICE_GET_UNALLOCATED = COMPANY_GET_BY_ID + "/customer-invoice/unallocated";
	export const CUSTOMER_INVOICE_GET_OVERDUE = COMPANY_GET_BY_ID + "/customer-invoice/overdue";
	export const CUSTOMER_INVOICE_PRINT = COMPANY_GET_BY_ID + "/customer-invoice/print/{customerInvoiceId}";
	export const CUSTOMER_INVOICE_PRINT_LIST = COMPANY_GET_BY_ID + "/customer-invoice/print-list";
	export const CUSTOMER_INVOICE_SEND_LIST = COMPANY_GET_BY_ID + "/customer-invoice/send-list";
	export const CUSTOMER_INVOICE_SEND_OVERDUE_REMINDERS = COMPANY_GET_BY_ID + "/customer-invoice/send-overdue-reminders";

	export const CUSTOMER_INVOICE_LINE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer-invoice-line";
	export const CUSTOMER_INVOICE_LINE_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/customer-invoice-line/bulk";
	export const CUSTOMER_INVOICE_LINE_GET_BY_ID = COMPANY_GET_BY_ID + "/customer-invoice-line/{customerInvoiceLineId}";
	export const CUSTOMER_INVOICE_LINE_GET_ALL = COMPANY_GET_BY_ID + "/customer-invoice-line/all";
	export const CUSTOMER_INVOICE_LINE_GET_ALL_FOR_INVOICE = COMPANY_GET_BY_ID
			+ "/customer-invoice-line/all/{customerInvoiceId}";
	export const CUSTOMER_INVOICE_LINE_MOVE_UP = COMPANY_GET_BY_ID + "/customer-invoice-line/up/{customerInvoiceLineId}";
	export const CUSTOMER_INVOICE_LINE_MOVE_DOWN = COMPANY_GET_BY_ID + "/customer-invoice-line/down/{customerInvoiceLineId}";
	export const CUSTOMER_INVOICE_LINE_COPY = COMPANY_GET_BY_ID + "/customer-invoice-line/copy";

	export const CUSTOMER_QUOTE_COPY = COMPANY_GET_BY_ID + "/customer-quote/copy/{customerQuoteId}";
	export const CUSTOMER_QUOTE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer-quote";
	export const CUSTOMER_QUOTE_GET_BY_ID = COMPANY_GET_BY_ID + "/customer-quote/{customerQuoteId}";
	export const CUSTOMER_QUOTE_GET_ALL = COMPANY_GET_BY_ID + "/customer-quote/all";
	export const CUSTOMER_QUOTE_PRINT = COMPANY_GET_BY_ID + "/customer-quote/print/{customerQuoteId}";

	export const CUSTOMER_QUOTE_LINE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/customer-quote-line";
	export const CUSTOMER_QUOTE_LINE_GET_BY_ID = COMPANY_GET_BY_ID + "/customer-quote-line/{customerQuoteLineId}";
	export const CUSTOMER_QUOTE_LINE_GET_ALL = COMPANY_GET_BY_ID + "/customer-quote-line/all/{customerQuoteId}";
	export const CUSTOMER_QUOTE_LINE_MOVE_UP = COMPANY_GET_BY_ID + "/customer-quote-line/up/{customerQuoteLineId}";
	export const CUSTOMER_QUOTE_LINE_MOVE_DOWN = COMPANY_GET_BY_ID + "/customer-quote-line/down/{customerQuoteLineId}";
	export const CUSTOMER_QUOTE_LINE_COPY = COMPANY_GET_BY_ID + "/customer-quote-line/copy";

	export const DASHBOARD_DATA_GET = COMPANY_GET_BY_ID + "/dashboard-data";

	export const DATA_ANALYSIS_CASHFLOW_GET_DATA = COMPANY_GET_BY_ID + "/data-analysis/cashflow/data";

	export const EMAIL_CHECK_REQUEST = COMPANY_GET_BY_ID + "/email-check-request";
	export const EMAIL_CHECK_RESULTS = COMPANY_GET_BY_ID + "/email-check-results";

	export const EXTERNAL_DATA_EXPORT_FAIL = COMPANY_GET_BY_ID + "/external-data-export/{externalDataExportId}/fail";
	export const EXTERNAL_DATA_EXPORT_FINISH = COMPANY_GET_BY_ID + "/external-data-export/{externalDataExportId}/finish";
	export const EXTERNAL_DATA_EXPORT_GET_FILE_BY_ID = COMPANY_GET_BY_ID + "/external-data-export/file/{fileId}";

	export const EXTERNAL_DATA_IMPORT_COUNTRIES = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/countries";
	export const EXTERNAL_DATA_IMPORT_CURRENCIES = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/currencies";
	export const EXTERNAL_DATA_IMPORT_CURRENCY_HISTORY = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/currency-history";
	export const EXTERNAL_DATA_IMPORT_CUSTOMER_INVOICES = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/customer-invoices";
	export const EXTERNAL_DATA_IMPORT_CUSTOMERS = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/customers";
	export const EXTERNAL_DATA_IMPORT_FAIL = COMPANY_GET_BY_ID + "/external-data-import/{externalDataImportId}/fail";
	export const EXTERNAL_DATA_IMPORT_FINISH = COMPANY_GET_BY_ID + "/external-data-import/{externalDataImportId}/finish";
	export const EXTERNAL_DATA_IMPORT_SUPPLIER_BANK_ACCOUNTS = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/supplier-bank-accounts";
	export const EXTERNAL_DATA_IMPORT_SUPPLIER_INVOICES = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/supplier-invoices";
	export const EXTERNAL_DATA_IMPORT_SUPPLIERS = COMPANY_GET_BY_ID
			+ "/external-data-import/{externalDataImportId}/suppliers";

	export const EXTERNAL_DATA_SYNC_REQUEST = COMPANY_GET_BY_ID + "/external-data-sync-request";
	export const EXTERNAL_DATA_SYNC_REQUEST_GET_ALL = COMPANY_GET_BY_ID + "/external-data-sync-request/all";
	export const EXTERNAL_DATA_SYNC_REQUEST_GET_BY_ID = COMPANY_GET_BY_ID
			+ "/external-data-sync-request/{externalDataSyncRequestId}";
	export const EXTERNAL_DATA_SYNC_REQUEST_QUERY_ONE_ACTIVE = COMPANY_GET_BY_ID + "/external-data-sync-request/one-active";
	export const EXTERNAL_DATA_SYNC_REQUEST_QUERY_ONE_ACTIVE_AS_REGEX = REGEX_ANY_TENANT_AND_COMPANY_ID
			+ "/external-data-sync-request/one-active";

	// To do: Review the PAYMENT endpoints - perhaps we won't need the
	// CREATE_OR_UPDATE in this way
	export const PAYMENT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/payment";
	export const PAYMENT_CREATE_BULKS_FROM_INVOICES = COMPANY_GET_BY_ID + "/payment-create-bulks-from-invoices";
	export const PAYMENT_GET_BY_ID = COMPANY_GET_BY_ID + "/payment/{paymentId}";
	export const PAYMENT_GET_ALL = COMPANY_GET_BY_ID + "/payment/all";
	export const PAYMENT_GET_ERRONEOUS = COMPANY_GET_BY_ID + "/payment/erroneous";
	export const PAYMENT_GET_PROCESSED = COMPANY_GET_BY_ID + "/payment/processed";
	export const PAYMENT_GET_SENT_TO_BANK_UNSIGNED = COMPANY_GET_BY_ID + "/payment/sent-to-bank-unsigned";
	export const PAYMENT_GET_SENT_TO_BANK_SIGNED = COMPANY_GET_BY_ID + "/payment/sent-to-bank-signed";
	export const PAYMENT_GET_TOTAL_BY_ID_LIST = COMPANY_GET_BY_ID + "/payment/total-amount";
	export const PAYMENT_GET_TOTAL_FROM_SIMULATED = COMPANY_GET_BY_ID + "/payment/total-simulated-amount";
	export const PAYMENT_GET_UNALLOCATED = COMPANY_GET_BY_ID + "/payment/unallocated";
	export const PAYMENT_GET_UNSIGNED = COMPANY_GET_BY_ID + "/payment/unsigned";

	export const PAYMENT_BULK = COMPANY_GET_BY_ID + "/payment-bulk";
	export const PAYMENT_BULK_CREATE = COMPANY_GET_BY_ID + "/payment-bulk-create";
	export const PAYMENT_BULK_CREATE_FILES = COMPANY_GET_BY_ID + "/payment-bulk-create-files";
	export const PAYMENT_BULK_GET_ALL = COMPANY_GET_BY_ID + "/payment-bulk/all";
	export const PAYMENT_BULK_GET_BY_ID = COMPANY_GET_BY_ID + "/payment-bulk/{paymentBulkId}";
	export const PAYMENT_BULK_GET_PREPARED = COMPANY_GET_BY_ID + "/payment-bulk/prepared";
	export const PAYMENT_BULK_GET_UNALLOCATED = COMPANY_GET_BY_ID + "/payment-bulk/unallocated";
	export const PAYMENT_BULK_GET_TOTAL_BY_ID_LIST = COMPANY_GET_BY_ID + "/payment-bulk/total-amount";
	export const PAYMENT_BULK_UPDATE = COMPANY_GET_BY_ID + "/payment-bulk-update";

	export const PAYMENT_FILE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/payment-file";
	export const PAYMENT_FILE_GET_FILTERED = COMPANY_GET_BY_ID + "/payment-file/filtered/{filter}";
	export const PAYMENT_FILE_RESET_FAILED_BULKS = COMPANY_GET_BY_ID + "/payment-file/reset-failed-bulks";
	export const PAYMENT_FILE_SIGN_INIT = COMPANY_GET_BY_ID + "/payment-file-sign-init";
	export const PAYMENT_FILE_SIGN_FINALIZE = COMPANY_GET_BY_ID + "/payment-file-sign-finalize";
	export const PAYMENT_FILE_SIGN_RESET = COMPANY_GET_BY_ID + "/payment-file-sign-reset";
	export const PAYMENT_FILE_SIGN_VIA_SMART_ID_INIT = COMPANY_GET_BY_ID + "/payment-file-sign-smart-id-init";
	export const PAYMENT_FILE_SIGN_VIA_SMART_ID_FINALIZE = COMPANY_GET_BY_ID + "/payment-file-sign-smart-id-finalize";

	export const PAYMENT_LINE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/payment-line";
	export const PAYMENT_LINE_GET_BY_ID = COMPANY_GET_BY_ID + "/payment-line/{paymentLineId}";
	export const PAYMENT_LINE_GET_ALL = COMPANY_GET_BY_ID + "/payment-line/all";
	export const PAYMENT_LINE_GET_PREPARED = COMPANY_GET_BY_ID + "/payment-line/prepared";

	export const PRODUCT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/product";
	export const PRODUCT_GET_BY_ID = COMPANY_GET_BY_ID + "/product/{productId}";
	export const PRODUCT_GET_ALL = COMPANY_GET_BY_ID + "/product/all";

	export const REPORT_DOWNLOAD = COMPANY_GET_BY_ID + "/report-download/{reportId}";
	export const REPORT_OF_BANK_STATEMENT_GET_ALL = COMPANY_GET_BY_ID + "/report/bank-statement/all";
	export const REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_JSON = COMPANY_GET_BY_ID
			+ "/report/control-sum-customer-invoices-json";
	export const REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_XLS = COMPANY_GET_BY_ID + "/report/control-sum-customer-invoices-xls";
	export const REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_JSON = COMPANY_GET_BY_ID
			+ "/report/control-sum-supplier-invoices-json";
	export const REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_XLS = COMPANY_GET_BY_ID + "/report/control-sum-supplier-invoices-xls";

	export const SCHEDULED_BANK_FILE_RECEIVE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/scheduled-bank-file-receive";
	export const SCHEDULED_DATA_CLEANUP_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/scheduled-data-cleanup";
	export const SCHEDULED_EXTERNAL_DATA_IMPORT_REQUEST_CREATE_OR_UPDATE = COMPANY_GET_BY_ID
			+ "/scheduled-external-data-import-request";
	export const SCHEDULED_PAYMENT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/scheduled-payment";
	export const SCHEDULED_STATEMENT_REQUEST_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/scheduled-statement-request";
	export const SCHEDULED_SUPPLIER_INVOICE_RECEIVE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/scheduled-supplier-invoice-receive";

	export const SCHEDULED_TASK_EXECUTE = COMPANY_GET_BY_ID + "/scheduled-task/{scheduledTaskId}/execute";
	export const SCHEDULED_TASK_GET_ALL = COMPANY_GET_BY_ID + "/scheduled-task/all";
	export const SCHEDULED_TASK_GET_BY_ID = COMPANY_GET_BY_ID + "/scheduled-task/{scheduledTaskId}";

	export const SEND_NOTIFICATION = TENANT_GET_BY_ID + "/notification";

	export const SUPPLIER_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/supplier";
	export const SUPPLIER_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/supplier/bulk";
	export const SUPPLIER_CREATE_OR_UPDATE_WITH_BANK_ACCOUNTS = COMPANY_GET_BY_ID + "/supplier-and-bank-accounts";
	export const SUPPLIER_GET_BY_ID = COMPANY_GET_BY_ID + "/supplier/{supplierId}";
	export const SUPPLIER_GET_ALL = COMPANY_GET_BY_ID + "/supplier/all";
	export const SUPPLIER_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/supplier/external-id-mappings";

	export const SUPPLIER_BANK_ACCOUNT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/supplier-bank-account";
	export const SUPPLIER_BANK_ACCOUNT_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/supplier-bank-account/bulk";
	export const SUPPLIER_BANK_ACCOUNT_GET_BY_ID = COMPANY_GET_BY_ID + "/supplier-bank-account/{supplierBankAccountId}";
	export const SUPPLIER_BANK_ACCOUNT_GET_ALL = COMPANY_GET_BY_ID + "/supplier-bank-account/all";
	export const SUPPLIER_BANK_ACCOUNT_GET_ALL_BY_SUPPLIER_ID = COMPANY_GET_BY_ID + "/supplier-bank-account/all/{supplierId}";
	export const SUPPLIER_BANK_ACCOUNT_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID
			+ "/supplier-bank-account/external-id-mappings";

	export const SUPPLIER_INVOICE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/supplier-invoice";
	export const SUPPLIER_INVOICE_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/supplier-invoice/bulk";
	export const SUPPLIER_INVOICE_GET_BY_ID = COMPANY_GET_BY_ID + "/supplier-invoice/{supplierInvoiceId}";
	export const SUPPLIER_INVOICE_GET_ALL = COMPANY_GET_BY_ID + "/supplier-invoice/all";
	export const SUPPLIER_INVOICE_GET_EXTERNAL_ID_MAPPINGS = COMPANY_GET_BY_ID + "/supplier-invoice/external-id-mappings";
	export const SUPPLIER_INVOICE_GET_UNPAID = COMPANY_GET_BY_ID + "/supplier-invoice/unpaid";
	export const SUPPLIER_INVOICE_GET_UNALLOCATED = COMPANY_GET_BY_ID + "/supplier-invoice/unallocated";
	export const SUPPLIER_INVOICE_GET_UNALLOCATED_TOTALS = COMPANY_GET_BY_ID + "/supplier-invoice/unallocated/total";

	export const SUPPLIER_INVOICE_CANDIDATE = COMPANY_GET_BY_ID + "/supplier-invoice-candidate";
	export const SUPPLIER_INVOICE_CANDIDATE_CONVERT = COMPANY_GET_BY_ID + "/supplier-invoice-candidate/convert";
	export const SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT = COMPANY_GET_BY_ID
			+ "/supplier-invoice-candidate/{supplierInvoiceCandidateId}/download-attachment";
	export const SUPPLIER_INVOICE_CANDIDATE_GET_BY_ID = COMPANY_GET_BY_ID
			+ "/supplierInvoiceCandidate/{supplierInvoiceCandidateId}";
	export const SUPPLIER_INVOICE_CANDIDATE_GET_ALL = COMPANY_GET_BY_ID + "/supplier-invoice-candidate/all";
	export const SUPPLIER_INVOICE_CANDIDATE_REMATCH = COMPANY_GET_BY_ID + "/supplier-invoice-candidate/rematch";

	export const TAG_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/tag";
	export const TAG_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/tag/bulk";
	export const TAG_GET_BY_ID = COMPANY_GET_BY_ID + "/tag/{tagId}";
	export const TAG_GET_ALL = COMPANY_GET_BY_ID + "/tag/all";

	export const TASK_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/task";
	export const TASK_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/task/bulk";
	export const TASK_GET_BY_ID = COMPANY_GET_BY_ID + "/task/{taskId}";
	export const TASK_GET_ALL = COMPANY_GET_BY_ID + "/task/all";

	export const TIMELOG_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/timelog";
	export const TIMELOG_CREATE_OR_UPDATE_BULK = COMPANY_GET_BY_ID + "/timelog/bulk";
	export const TIMELOG_GET_BY_ID = COMPANY_GET_BY_ID + "/timelog/{timelogId}";
	export const TIMELOG_GET_ALL = COMPANY_GET_BY_ID + "/timelog/all";
	export const TIMELOG_GET_ALL_BY_TASK_ID = COMPANY_GET_BY_ID + "/timelog/all/{taskId}";

//	export const TIMELOG_TIMER_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/timelogTimer";
//	export const TIMELOG_TIMER_GET_BY_ID = COMPANY_GET_BY_ID + "/timelogTimer/{timelogTimerId}";
//	export const TIMELOG_TIMER_GET_ALL = COMPANY_GET_BY_ID + "/timelogTimer/all";
	export const TIMELOG_TIMER_GET_ALL_ACTIVE = TENANT_GET_BY_ID + "/timelog-timer/all";
	export const TIMELOG_TIMER_DELETE = TENANT_GET_BY_ID + "/timelog-timer/delete-by-task-id/{taskId}";
	export const TIMELOG_TIMER_PAUSE = TENANT_GET_BY_ID + "/timelog-timer/pause-by-task-id/{taskId}";
	export const TIMELOG_TIMER_START = TENANT_GET_BY_ID + "/timelog-timer/start-by-task-id/{taskId}";
	export const TIMELOG_TIMER_STOP = TENANT_GET_BY_ID + "/timelog-timer/stop-by-task-id/{taskId}";

	export const TENANT_LICENSE_GET_ALL = TENANT_GET_BY_ID + "/tenant-license/all";
	export const TENANT_LICENSE_UPDATE = "/tenant-license-update";

	export const UNIT_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/unit";
	export const UNIT_GET_BY_ID = COMPANY_GET_BY_ID + "/unit/{unitId}";
	export const UNIT_GET_ALL = COMPANY_GET_BY_ID + "/unit/all";

	export const USER_ACCOUNT_CREATE = "/user-create";
	export const USER_ACCOUNT_CREATE_FOR_TENANT_AND_SET_ADMIN = "/user-create-and-set-admin";
	export const USER_ACCOUNT_CREATE_FROM_INVITATION = "/user-create-from-invitation";
	export const USER_ACCOUNT_GET_ALL = TENANT_GET_BY_ID + "/user/all";
	export const USER_ACCOUNT_GET_BY_ID = TENANT_GET_BY_ID + "/user/{userAccountId}";
	export const USER_ACCOUNT_LOGIN = "/user-login";
	export const USER_ACCOUNT_LOGIN_CHECK = "/user-login-check";
	export const USER_ACCOUNT_LOGIN_VIA_SMART_ID_INIT = "/user-login-smart-id-init";
	export const USER_ACCOUNT_LOGIN_VIA_SMART_ID_FINALIZE = "/user-login-smart-id-finalize";
	export const USER_ACCOUNT_LOGOUT = "/user-logout";
	export const USER_ACCOUNT_PASSWORD_RESET = "/user-password-reset";
	export const USER_ACCOUNT_PASSWORD_RESET_REQUEST = "/user-password-reset-request";
	export const USER_ACCOUNT_RESEND_ACTIVATION = "/user-activation-resend";
	export const USER_ACCOUNT_UPDATE_DATA = TENANT_GET_BY_ID + "/user-update-data";
	export const USER_ACCOUNT_UPDATE_RIGHTS = TENANT_GET_BY_ID + "/user-update-rights";

	export const USER_ACTIVATE = "/user-activate/{token}";
	export const USER_ACTIVATE_REGEX = "/user-activate/*";

	export const USER_AUTHORIZATION_GET_ALL = TENANT_GET_BY_ID + "/user-authorization/all";

	export const USER_INVITATION_CREATE = TENANT_GET_BY_ID + "/user-invitation";

	export const VAT_RATE_CREATE_OR_UPDATE = COMPANY_GET_BY_ID + "/vat-rate";
	export const VAT_RATE_GET_BY_ID = COMPANY_GET_BY_ID + "/vat-rate/{vatRateId}";
	export const VAT_RATE_GET_ALL = COMPANY_GET_BY_ID + "/vat-rate/all";

	// end Everything else, in alphabetical order


